import type { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

import type { TChainIndicator, ChartsResources } from './types';
import type { TimeChartData } from 'ui/shared/chart/types';

import type { ResourcePayload } from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';

function formatDate(date: Date) {
  return date.toISOString().substring(0, 10);
}

function getStartDateInPast(months: number): Date {
  const date = new Date();
  date.setMonth(date.getMonth() - months);
  return date;
}

export default function useFetchChartData<R extends ChartsResources>(indicator: TChainIndicator<R> | undefined): UseQueryResult<TimeChartData> {
  const queryResult = useApiQuery(indicator?.api.resourceName || 'homepage_chart_txs', {
    queryOptions: { enabled: Boolean(indicator) },
  });

  return React.useMemo(() => {
    return {
      ...queryResult,
      data: queryResult.data && indicator ? indicator.api.dataFn(queryResult.data as ResourcePayload<R>) : queryResult.data,
    } as UseQueryResult<TimeChartData>;
  }, [ indicator, queryResult ]);
}

export function useFetchStatsLineChartData<R extends ChartsResources>(indicator: TChainIndicator<R> | undefined): UseQueryResult<TimeChartData> {
  const queryResult = useApiQuery('stats_line', {
    pathParams: { id: 'newInternalTxns' },
    queryParams: {
      from: formatDate(getStartDateInPast(1)),
      to: formatDate(new Date()),
    },
    queryOptions: { enabled: Boolean(indicator) },
  });

  return React.useMemo(() => {
    return {
      ...queryResult,
      data: queryResult.data && indicator ? indicator.api.dataFn(queryResult.data as ResourcePayload<R>) : queryResult.data,
    } as UseQueryResult<TimeChartData>;
  }, [ indicator, queryResult ]);
}
