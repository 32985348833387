import { Flex, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useGetPriceInfo from 'shared-modules/helpers/get-price-info';

import type { TChainIndicator } from './types';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import { HOMEPAGE_STATS } from 'stubs/stats';
import Hint from 'ui/shared/Hint';

import ChainIndicatorChartContainer from './ChainIndicatorChartContainer';
import ChainIndicatorItem from './ChainIndicatorItem';
import { useFetchStatsLineChartData } from './useFetchChartData';
import INDICATORS from './utils/indicators';

const indicators = INDICATORS
  .filter(({ id }) => config.UI.homepage.charts.includes(id))
  .sort((a, b) => {
    if (config.UI.homepage.charts.indexOf(a.id) > config.UI.homepage.charts.indexOf(b.id)) {
      return 1;
    }

    if (config.UI.homepage.charts.indexOf(a.id) < config.UI.homepage.charts.indexOf(b.id)) {
      return -1;
    }

    return 0;
  });

const ChainIndicators = () => {
  const [ selectedIndicator, selectIndicator ] = React.useState(indicators[0]?.id);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ formattedIndicator, setFormattedIndicator ] = useState<Array<any>>([]);

  const indicator = indicators.find(({ id }) => id === selectedIndicator);

  const queryResult = useFetchStatsLineChartData(indicator);
  const statsQueryResult = useApiQuery('homepage_stats', {
    fetchParams: {
      headers: {
        'updated-gas-oracle': 'true',
      },
    },
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });

  const bgColorDesktop = useColorModeValue('white', 'gray.900');
  const bgColorMobile = useColorModeValue('white', 'black');
  const listBgColorDesktop = useColorModeValue('gray.50', 'black');
  const listBgColorMobile = useColorModeValue('gray.50', 'gray.900');
  const { priceInfo } = useGetPriceInfo();

  if (indicators.length === 0) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (priceInfo) {
      formatData();
    }
  }, [ priceInfo ]);

  const formatData = () => {
    const dailyTxs = indicators.find(indi => indi.id === 'stats_line') as TChainIndicator<'stats_line'>;
    const _dailyTxs: TChainIndicator<'stats_line'> = {
      ...dailyTxs,
      value: () => queryResult.data?.at(0)?.items.slice(-1)[0].value?.toLocaleString(undefined, { maximumFractionDigits: 2, notation: 'compact' }),
    };
    const coinPrice = indicators.find((indi) => indi.id === 'coin_price') as TChainIndicator<'homepage_chart_market'>;
    const _coinPrice: TChainIndicator<'homepage_chart_market'> = {
      ...coinPrice,
      value: () => {
        return (
          <Flex>
            { '$' + Number(priceInfo?.usd_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 6 }) }
            <Text fontSize={ 14 } fontWeight={ 400 } color="gray.300">
              { ` ≈ ฿${ priceInfo?.thb_value }` }
            </Text>
          </Flex>
        );
      },
    };

    const marketCap = indicators.find((indi) => indi.id === 'market_cap') as TChainIndicator<'homepage_chart_market'>;
    const _marketCap: TChainIndicator<'homepage_chart_market'> = {
      ...marketCap,
      value: () => '$' + Number(priceInfo?.market_cap_usd).toLocaleString(undefined, { maximumFractionDigits: 0, notation: 'compact' }),
    };
    setFormattedIndicator([ _dailyTxs, _coinPrice, _marketCap ]);
  };

  const valueTitle = (() => {
    if (statsQueryResult.isPlaceholderData) {
      return <Skeleton h="48px" w="215px" mt={ 3 } mb={ 4 }/>;
    }

    if (!statsQueryResult.data) {
      return <Text mt={ 3 } mb={ 4 }>There is no data</Text>;
    }

    return (
      <Text fontWeight={ 600 } fontFamily="heading" fontSize="48px" lineHeight="48px" mt={ 3 } mb={ 4 }>
        { queryResult.data?.at(0)?.items.slice(-1)[0].value?.toLocaleString(undefined, { maximumFractionDigits: 2, notation: 'compact' }) }
      </Text>
    );
  })();

  return (
    <Flex
      p={{ base: 0, lg: 8 }}
      borderRadius={{ base: 'none', lg: 'lg' }}
      boxShadow={{ base: 'none', lg: 'xl' }}
      bgColor={{ base: bgColorMobile, lg: bgColorDesktop }}
      columnGap={ 12 }
      rowGap={ 0 }
      flexDir={{ base: 'column', lg: 'row' }}
      w="100%"
      alignItems="stretch"
      mt={ 8 }
    >
      <Flex flexGrow={ 1 } flexDir="column" order={{ base: 2, lg: 1 }} p={{ base: 6, lg: 0 }}>
        <Flex alignItems="center">
          <Text fontWeight={ 500 } fontFamily="heading" fontSize="lg">{ indicator?.title }</Text>
          { indicator?.hint && <Hint label={ indicator.hint } ml={ 1 }/> }
        </Flex>
        { valueTitle }
        <ChainIndicatorChartContainer { ...queryResult }/>
      </Flex>
      { indicators.length > 1 && (
        <Flex
          flexShrink={ 0 }
          flexDir="column"
          as="ul"
          p={ 3 }
          borderRadius="lg"
          bgColor={{ base: listBgColorMobile, lg: listBgColorDesktop }}
          rowGap={ 3 }
          order={{ base: 1, lg: 2 }}
        >
          { formattedIndicator.map((indicator) => (
            <ChainIndicatorItem
              key={ indicator.id }
              { ...indicator }
              isSelected={ selectedIndicator === indicator.id }
              onClick={ selectIndicator }
              stats={ statsQueryResult }
            />
          )) }
        </Flex>
      ) }
    </Flex>
  );
};

export default ChainIndicators;
