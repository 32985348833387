import axios from 'axios';
import { useEffect, useState } from 'react';

import config from 'configs/app/features/price';

export interface PriceInfoProps {
  available_supply: number;
  market_cap_usd: number;
  price_change_percentage_24h: number;
  symbol: string;
  thb_value: number;
  total_supply: number;
  usd_value: number;
  volume_24h_usd: number;
}

const useGetPriceInfo = () => {
  const [ priceInfo, setPriceInfo ] = useState<PriceInfoProps | null>(null);
  const apiEndpoint = config.baseUrl;

  useEffect(() => {
    delayGetPriceInfo();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getPriceInfo();
    }, 10000); // 10 seconds
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearInterval(intervalId as any);
    };
  }, []);

  const timeout = (delay: number) => {
    return new Promise(res => setTimeout(res, delay));
  };

  const delayGetPriceInfo = async() => {
    await timeout(1000);
    getPriceInfo();
  };

  const getPriceInfo = async() => {
    try {
      const { data } = await axios.get(apiEndpoint);
      const result: PriceInfoProps = data?.[0];
      setPriceInfo(result);
    } catch {
    }
  };

  return {
    priceInfo,
  };

};

export default useGetPriceInfo;
